<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :class="b('title')"
         v-html="titleFormatter && titleFormatter(dataChart)"></div>
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
  import create from "../../create";
  import AutoToolTip from '@/utils/auto-tooltip.js'

  export default create({
    name: "pie",
    computed: {
      labelShow () {
        return this.vaildData(this.option.labelShow, false);
      },
      x2 () {
        return this.option.gridX2 || 20;
      },
      fontSize () {
        return this.option.fontSize || 14;
      },
      carousel () {
        return this.option.carousel || false;
      },
      carouselTime () {

        if (window.isNaN(Number(this.option.carouselTime))) {
          return this.timeDefault;
        } else if (this.option.carouselTime < this.timeMin) {
          return this.timeMin;
        } else if (this.option.carouselTime > this.timeMax) {
          return this.timeMax;
        }
        return this.option.carouselTime;
      }
    },
    data() {
      return {
        timeDefault: 2000,
        timeMin: 2000,
        timeMax: 30000,
        autoToolTip: undefined
      }
    },
    methods: {
      updateChart () {
        const optionData = this.deepClone(this.dataChart) || [];
        const option = {
          title: this.ishasprop(this.option.titleShow, {
            text: this.option.title,
            subtext: this.option.subtext || '',
            textStyle: {
              color: this.option.titleColor || '#333',
              fontFamily: this.option.titleFontFamily,
              fontSize: this.option.titleFontSize || 16
            },
            left: this.option.titlePostion || 'auto',
            subtextStyle: {
              color: this.option.subTitleColor || '#aaa',
              fontFamily: this.option.subTitleFontFamily,
              fontSize: this.option.subTitleFontSize || 14
            }
          }, {}),
          tooltip: (() => {
            return Object.assign(
                    (() => {
                      if (this.formatter) {
                        return {
                          formatter: name => {
                            return this.formatter(name, this.dataChart);
                          }
                        };
                      }
                      return {};
                    })(),
                    {
                      textStyle: {
                        fontFamily: this.option.tipFontFamily,
                        fontSize: this.option.tipFontSize,
                        color: this.option.tipColor || "#fff"
                      }
                    }
            );
          })(),
          grid: {
            left: this.option.gridX || 20,
            top: this.option.gridY || 60,
            right: this.x2,
            bottom: this.option.gridY2 || 60
          },
          legend: {
            show: this.vaildData(this.option.legend, false),
            orient: this.option.legendOrient || "vertical",
            x: this.option.legendPostion || "left",
            top: 0,
            right: this.x2,
            textStyle: {
              fontFamily: this.option.legendFontFamily,
              fontSize: this.option.legendFontSize || 12,
            },
            data: (() => {
              return (Array.isArray(optionData) ? optionData : []).map((ele, index) => {
                return {
                  name: ele.name,
                  textStyle: this.ishasprop(!this.switchTheme, {
                    color: this.getColor(index, true)
                  }, {})
                }
              });
            })()
          },
          series: (() => {
            const barColor = this.option.barColor || [];
            const list = [
              {
                type: "pie",
                roseType: this.option.roseType ? "radius" : "",
                radius: this.option.radius ? ["40%", "55%"] : "50%",
                center: ["50%", "60%"],
//                selectedMode: 'series',
//                selectedOffset: 10,
                animationType: "scale",
                animationEasing: "elasticOut",
                animationDelay: function (idx) {
                  return Math.random() * 200;
                },
                label: {
                  normal: {
                    show: this.labelShow,
                    formatter: '{b}:{c}({d}%)',
                    textStyle: {
                      fontSize: this.fontSize
                    }
                  }
                },
                data: (() => {
                  let list = optionData;
                  if (this.option.notCount) {
                    list = list.filter(ele => {
                      if (ele.value !== 0 && ele.value) {
                        return true;
                      }
                    });
                  }
                  if (this.option.sort) {
                    list.sort(function (a, b) {
                      return a.value - b.value;
                    });
                  }
                  return list;
                })(),
                itemStyle: this.ishasprop(!this.switchTheme, {
                  color: params => this.getColor(params.dataIndex)
                }, {
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                  }
                }),
              }
            ];
            return list;
          })()
        };
        this.myChart.resize();
        this.myChart.setOption(option, true);

        if (!this.autoToolTip) {
          this.autoToolTip = new AutoToolTip(this.myChart);
        }

        this.autoToolTip.clearLoop();
        this.autoToolTip.showLoop(option, {
          interval: this.carouselTime,
          loopSeries: this.carousel,
          seriesIndex: 0
        });
      }
    }
  });
</script>



