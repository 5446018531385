<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";
// import doMove from '@/utils/goMove-x.js'
import AutoToolTip from '@/utils/auto-tooltip.js'

export default create({
  name: "bar",
  computed: {
    x2 () {
      return this.option.gridX2 || 20;
    },
    showLine () {
      if (window.isNaN(Number(this.option.showLine))) {
        return this.lineDefault;
      } else if (this.option.showLine < this.lineMin) {
        return this.lineMin;
      } else if (this.option.showLine > this.lineMax) {
        return this.lineMax;
      }
      return this.option.showLine;
    },
    carousel () {
      return this.option.carousel || false;
    },
    carouselTime () {
      if (window.isNaN(Number(this.option.carouselTime))) {
        return this.timeDefault;
      } else if (this.option.carouselTime < this.timeMin) {
        return this.timeMin;
      } else if (this.option.carouselTime > this.timeMax) {
        return this.timeMax;
      }
      return this.option.carouselTime;
    }
  },
  data() {
    return {
      timeDefault: 2000,
      timeMin: 2000,
      timeMax: 30000,
      lineDefault: 5,// 默认显示5行
      lineMin: 1,// 最少显示1行
      lineMax: 100,// 最多显示100行
      seriesList: [],
      autoToolTip: undefined
    };
  },
  methods: {
    updateChart () {
      const optionData = this.deepClone(this.dataChart);

      // =========================================
      // var _this = this;
      // _this.seriesList.forEach(function(element) {
      //   if (element.moveAnimate) {
      //     element.moveAnimate.destory()
      //     element.moveAnimate = null
      //   }
      // });
      //
      // _this.seriesList = [];
      // optionData.series.forEach(function(element, seriesIndex) {
      //   _this.seriesList[seriesIndex] = {
      //     // 控制显示条数
      //     showLine: element.data.length < _this.option.showLine ? element.data.length: _this.option.showLine ? _this.option.showLine: _this.lineDefault,
      //     // 数据长度
      //     dataLength: element.data.length
      //   }
      // });
      // =========================================

      const option = {
        title: this.ishasprop(this.option.titleShow, {
          text: this.option.title,
          subtext: this.option.subtext || '',
          textStyle: {
            color: this.option.titleColor || '#333',
            fontSize: this.option.titleFontSize || 16
          },
          left: this.option.titlePostion || 'auto',
          subtextStyle: {
            color: this.option.subTitleColor || '#aaa',
            fontSize: this.option.subTitleFontSize || 14
          }
        }, {}),
        tooltip: this.ishasprop(this.formatter, {
          formatter: name => { return this.formatter(name, this.dataChart) }
        }, {
          textStyle: {
            fontSize: this.option.tipFontSize,
            color: this.option.tipColor || "#fff"
          }
        }),
        grid: {
          left: this.option.gridX || 20,
          top: this.option.gridY || 60,
          right: this.x2,
          bottom: this.option.gridY2 || 60
        },
        legend: {
          show: this.vaildData(this.option.legend, false),
          orient: this.option.legendOrient || "vertical",
          x: this.option.legendPostion || "left",
          top: 0,
          right: this.x2,
          textStyle: {
            fontSize: this.option.legendFontSize || 12,
          },
          data: (() => {
            return (optionData.series || []).map((ele, index) => {
              return {
                name: ele.name,
                textStyle: this.ishasprop(!this.switchTheme, {
                  color: this.getColor(index, true)
                }, {})
              };
            });
          })()
        },
        xAxis: {
          type: this.option.category ? "value" : "category",
          name: this.option.xAxisName,
          axisLine: {
            lineStyle: {
              color: this.option.lineColor || "#333"
            }
          },
          data: optionData.categories || [],
          inverse: this.vaildData(this.option.xAxisInverse, false),
          show: this.vaildData(this.option.xAxisShow, true),
          splitLine: {
            show: this.vaildData(this.option.xAxisSplitLineShow, false)
          },
          axisLabel: {
            interval: this.option.xAxisinterval || 'auto',
            rotate: this.option.xAxisRotate || 0,
            textStyle: {
              color: this.option.nameColor || "#333",
              fontSize: this.option.xNameFontSize || 14
            }
          }
        },
        yAxis: {
          type: this.option.category ? "category" : "value",
          name: this.option.yAxisName,
          data: optionData.categories || [],
          axisLabel: {
            textStyle: {
              color: this.option.nameColor || "#333",
              fontSize: this.option.yNameFontSize || 14
            }
          },
          axisLine: {
            lineStyle: {
              color: this.option.lineColor || "#333"
            }
          },
          inverse: this.vaildData(this.option.yAxisInverse, false),
          show: this.vaildData(this.option.yAxisShow, true),
          splitLine: {
            show: this.vaildData(this.option.yAxisSplitLineShow, true)
          }
        },
        series: (() => {
          const barColor = this.option.barColor || [];
          const list = (optionData.series || []).map((ele, index) => {
            return Object.assign(ele, {
              type: "bar",
              stack: ele.stack,
              barWidth: this.option.barWidth || 16,
              barMinHeight: this.option.barMinHeight || 0,
              itemStyle: this.ishasprop(!this.switchTheme, {
                color: this.getColor(index)
              }, { barBorderRadius: this.option.barRadius || 0 }),
              label: {
                show: this.vaildData(this.option.labelShow, false), //开启显示
                position: this.option.category ? "right" : "top", //在上方显示,
                formatter: name => this.getLabelFormatter(name),
                textStyle: {
                  //数值样式
                  fontSize: this.option.labelShowFontSize || 14,
                  color: this.option.labelShowColor || "#333",
                  fontWeight: this.option.labelShowFontWeight || 500
                }
              }
            });
          });
          return list;
        })()
      };
      // =========================================
      // if (this.option.carousel) {
      //   option.dataZoom = [
      //     {
      //       show: false,
      //       type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
      //       startValue: 0, // 从头开始。
      //       // 一次性展示五个。如果未开启carousel则为数据全集
      //       endValue: this.option.carousel?_this.seriesList[0].showLine-1:_this.seriesList[0].dataLength
      //     }
      //   ];
      //   if (this.option.category) {
      //     option.dataZoom[0].yAxisIndex = [0]
      //   } else {
      //     delete option.dataZoom[0]["yAxisIndex"]
      //   }
      // } else {
      //   delete option["dataZoom"]
      // }
      // =========================================

      this.myChart.resize();
      this.myChart.setOption(option, true);

      if (!this.autoToolTip) {
        this.autoToolTip = new AutoToolTip(this.myChart);
      }

      this.autoToolTip.clearLoop();
      this.autoToolTip.showLoop(option, {
        interval: this.carouselTime,
        loopSeries: this.carousel,
        seriesIndex: 0
      });











      // =========================================
      // if (this.option.carousel) {
      //   _this.seriesList.forEach(function (element) {
      //     if (element.moveAnimate) {
      //       element.moveAnimate.destory()
      //       element.moveAnimate = null
      //     } else {
      //       if (element.showLine != element.dataLength) {
      //         element.moveAnimate = new doMove(_this.myChart,
      //                 option,
      //                 element.showLine,
      //                 element.dataLength,
      //                 _this.carouselTime)
      //         element.moveAnimate.autoMove()
      //       }
      //     }
      //   });
      // }
      // =========================================
    }
  }
});

</script>
