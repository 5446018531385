/**
 *  echarts tooltip 自动轮播
 *
 *  @param chart
 *  @param chartOption
 *  @param options
 *  {
 *  <li>"interval": 轮播时间间隔，单位毫秒，默认为2000
 *  <li>"loopSeries": boolean类型，默认为false。
 *              true表示循环所有series的tooltip，false则显示指定seriesIndex的tooltip
 * 	<li>"seriesIndex": 默认为0，指定某个系列（option中的series索引）循环显示tooltip，
 * 	            当loopSeries为true时，从seriesIndex系列开始执行.
 *  <li>"updateData": 自定义更新数据的函数，默认为null；用于类似于分页的效果，比如总数据有20条，chart一次只显示5条，全部数据可以分4次显示。
 *  }
 * @returns {{clearLoop: clearLoop}}
 */
class AutoToolTip {

    defaultOptions = {
        interval: 2000,
        loopSeries: false,
        seriesIndex: 0,
        updateData: null
    };

    constructor(charts) {
        this.chart = charts
        this.options = this.defaultOptions;
        this.zRender = this.chart.getZr();

        this.seriesIndex = 0; // 系列索引
        this.seriesLen = 0; // 系列个数
        this.dataIndex = 0; // 数据索引，初始化为-1，是为了判断是否是第一次执行
        this.dataLen = 0; // 某个系列数据个数

        this.chartType; // 系列类型
        this.timeTicket;
        this.first = true;
    }

    showLoop(chartOption, options) {

        this.chartOption = chartOption //echarts选项
        this.seriesLen = this.chartOption.series.length; // 系列个数

        // 不循环series时seriesIndex指定显示tooltip的系列，不指定默认为0，指定多个则默认为第一个
        // 循环series时seriesIndex指定循环的series，不指定则从0开始循环所有series，指定单个则相当于不循环，指定多个
        // 要不要添加开始series索引和开始的data索引？
        if (options) {
            options.interval = options.interval || this.defaultOptions.interval;
            options.loopSeries = options.loopSeries || this.defaultOptions.loopSeries;
            options.seriesIndex = options.seriesIndex || this.defaultOptions.seriesIndex;
            options.updateData = options.updateData || this.defaultOptions.updateData;
        } else {
            options = this.defaultOptions;
        }

        // 如果设置的seriesIndex无效，则默认为0
        if (options.seriesIndex < 0 || options.seriesIndex >= this.seriesLen) {
            this.seriesIndex = 0;
        } else {
            this.seriesIndex = options.seriesIndex;
        }
        this.options = options;


        if (!options.loopSeries) {
            return;
        }

        // 鼠标在echarts图上时停止轮播
        this.chart.on('mousemove', () => {this.stopAutoShow()});
        this.zRender.on('mousemove', (param) => {this.zRenderMouseMove(param)});
        this.zRender.on('globalout', () => {this.zRenderGlobalOut()});
        this.autoShowTip();
    }

    clearLoop() {
        this.stopAutoShow()
        // if (this.timeTicket) {
        //     clearInterval(this.timeTicket);
        //     this.timeTicket = 0;
        // }

        this.chart.off('mousemove', () => {this.stopAutoShow()});
        this.zRender.off('mousemove', (param) => {this.zRenderMouseMove(param)});
        this.zRender.off('globalout', () => {this.zRenderGlobalOut()});
    }

    // 关闭轮播
    stopAutoShow() {
        if (this.timeTicket) {
            clearInterval(this.timeTicket);
            this.timeTicket = 0;
            if (this.chartType === 'pie' || this.chartType === 'radar' || this.chartType === "map" || this.chartType === "scatter") {
                // 取消高亮的图形
                this.chart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: this.options.loopSeries ? (this.seriesIndex === 0 ? this.seriesLen - 1 : this.seriesIndex - 1) : this.seriesIndex,
                    dataIndex: this.dataIndex === 0 ? this.dataLen - 1 : this.dataIndex - 1
                });
            }
        }
    }

    zRenderMouseMove(param) {
        if (param.event) {
            // 阻止canvas上的鼠标移动事件冒泡
            param.event.cancelBubble = true;
        }
        this.stopAutoShow();
    }

    // 离开echarts图时恢复自动轮播
    zRenderGlobalOut() {
        if (!this.timeTicket) {
            this.autoShowTip();
        }
    }

    autoShowTip() {
        this.showTip(this);
        this.timeTicket = setInterval(()=>{
            this.showTip(this)
        }, this.options.interval);
    }

    showTip(_this) {
        // 判断是否更新数据
        if (_this.dataIndex === 0 && !_this.first && typeof _this.options.updateData === "function") {
            _this.options.updateData();
            _this.chart.setOption(_this.chartOption);
        }

        let series = _this.chartOption.series;
        _this.chartType = series[_this.seriesIndex].type; // 系列类型
        _this.dataLen = series[_this.seriesIndex].data.length; // 某个系列的数据个数

        var tipParams = {seriesIndex: _this.seriesIndex};

        switch (_this.chartType) {
            case 'map':
            case 'pie':
            case 'chord':
                tipParams.name = series[_this.seriesIndex].data[_this.dataIndex].name;
                break;
            case 'radar': // 雷达图
                tipParams.seriesIndex = _this.seriesIndex;
                tipParams.dataIndex = _this.dataIndex;
                break;
            default:
                tipParams.dataIndex = _this.dataIndex;
                break;
        }

        if (_this.chartType === 'pie' || _this.chartType === 'radar' || _this.chartType === 'map' || _this.chartType === 'scatter') {
            // 取消之前高亮的图形
            _this.chart.dispatchAction({
                type: 'downplay',
                seriesIndex: _this.options.loopSeries ? (_this.seriesIndex === 0 ? _this.seriesLen - 1 : _this.seriesIndex - 1) : _this.seriesIndex,
                dataIndex: _this.dataIndex === 0 ? _this.dataLen - 1 : _this.dataIndex - 1
            });

            // 高亮当前图形
            _this.chart.dispatchAction({
                type: 'highlight',
                seriesIndex: _this.seriesIndex,
                dataIndex: _this.dataIndex
            });
        }

        // 显示 tooltip
        tipParams.type = 'showTip';

        _this.dataIndex = (_this.dataIndex + 1) % _this.dataLen;
        if (_this.options.loopSeries && _this.dataIndex === 0 && !_this.first) { // 数据索引归0表示当前系列数据已经循环完
            _this.seriesIndex = (_this.seriesIndex + 1) % _this.seriesLen;
        }
        try {
            _this.chart.dispatchAction(tipParams);
        } catch (e) {
            console.log(e)
        }

        _this.first = false;
    }
}
export default AutoToolTip

// let currentPage = 0; // 当前页
// let counts = 2; // 每页条数
// let total = data.length; // 总数
// let totalPage = Math.ceil(total / counts); // 总页数
// function updateData() {
//     let xAxisData = [];
//     let seriesData = [];
//
//     let currentData = data.slice(currentPage * counts, (currentPage + 1) * counts);
//     currentData.forEach(function (item) {
//         xAxisData.push(item.name);
//         seriesData.push(item.value);
//     });
//
//     currentPage = ++currentPage % totalPage;
//
//     bar.xAxis.data = xAxisData;
//     bar.series[0].data = seriesData;
// }
